import React from "react"

import Container from "react-bootstrap/Container"
import { Row, Col } from "react-bootstrap"

import Carousel from "react-bootstrap/Carousel"
import ResponsiveEmbed from "react-bootstrap/ResponsiveEmbed"
import SEO from "../../components/seo"
import Image from "../../components/image"
import Layout from "../../components/layout"
import Paginator from "../../components/paginator"

const Alsterkrone = ({ location }) => (
  <Layout>
    <SEO title="Luk Vermeulen" />
    <Container>
      <div className="row mt-3">
        <div className="col-12">
          <Image
            filename="BmT/work/00_Binnenalster.jpg"
            alt="architecture visualization of the alsterkrone on the binnenalster"
          />
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-md-4">
          <dl>
            <dt>Title</dt>
            <dd>
              Alsterkrone | <small>Team of 2</small>
            </dd>
            <dt>Type</dt>
            <dd>Pavillion</dd>
            <dt>Year</dt>
            <dd>2019</dd>
          </dl>
        </div>
        <div className="col-md-8">
          <p>
            The Alstercrown is a new way of transportation for the Hamburger
            Binnenalster. The light tensile structure, held up by the elegant
            steel crown that gives the transport pavillion its name. Driving
            autonomously, the Alsterkrone picks up and transports people where
            needed.
          </p>
        </div>
      </div>
      <Row className="mt-3">
        {/* <Col>
          <Image
            filename="BmT/work/04_Grundriss.png"
            alt="architecture floorplan of tensile construction"
          />
        </Col> */}
        <Col>
          <Image
            filename="BmT/work/05_Schnitt.png"
            alt="section of tensile construction"
          />
        </Col>
      </Row>
      <Carousel className="mt-3">
        <Carousel.Item>
          <Image
            filename="BmT/work/01_Alsterkrone.jpg"
            alt="Physical architecture model"
          />
        </Carousel.Item>
        <Carousel.Item>
          <Image
            filename="BmT/work/02_Jungfernstieg.jpg"
            alt="tensile architecture physical model"
          />
        </Carousel.Item>
        <Carousel.Item>
          <Image
            filename="BmT/work/03_Landung.jpg"
            alt="tensile structure physical model"
          />
        </Carousel.Item>
      </Carousel>
      <Paginator currentPathname={location.pathname} />
    </Container>
  </Layout>
)

export default Alsterkrone
